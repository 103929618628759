<template>
   <div>
     <CCard class="col-md-12">
       <CRow class="p-3">
       <h4 class="color-light ml-2 page_title"><span class="ul_text">AP</span>K SECURITY</h4>
      </CRow>
      <CCardBody class="col-md-12">
            <CRow>
              <CTextarea
                class="col-md-8"
                label="APK Blacklist"
                size="sm"
                v-model="apk.apk_blacklist"
                required
                placeholder="APK Blacklist"
                :state="validation"
                maxlength="2000"
              ></CTextarea>
              <!-- <b-form-invalid-feedback
                style="margin-left:160px;"
                :state="validation"
              >Please add a name for the traffic source which will help you identify it later</b-form-invalid-feedback> -->
            <p class="col-md-8" style="font-size: 12px;">Customer APK Blacklist: All visits from these APKs will be blocked independent of your security settings. Please enter a comma separated list of APKs.</p>
            </CRow>
            
            <CRow class="mt-3 col-md-12">
              <CButton type="submit" v-on:click="onSubmit" color="success">Save</CButton>
            </CRow>
      </CCardBody></CCard>
   </div> 
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import SettingService from './../../services/settingControlService'
export default {
    data(){
        return{
            apk:{apk_blacklist:''},
        }
    },
  async mounted() {
    if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }
    else{
      try {
        let response = await SettingService.apkget()
      
      if(response.message){
      this.apk.apk_blacklist = response.message.apkbklist.toString()
      }
    } catch (e) {
      // this.errorNotify("Error", "connection error", "danger");
    }
    }
    },
    computed: {
    validation() {
    //   if (this.mainObject.offername == "") {
    //     return false;
    //   }
    }
  },
    methods: {
    async onSubmit(){
      try {
     
      let apk_data = {
          apkbklist: this.apk.apk_blacklist
      }
      let response = await SettingService.apk(apk_data)
       if(response.result){
          Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
        else{
          Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
      } catch (e) {
          // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
        // this.$emit('ready', false)
        // this.errorNotify("Error", "connection error", "danger");
    }
    }
}
}
</script>
